import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const propertiesBlock = document.querySelectorAll('.three-properties');

  if (0 < propertiesBlock.length) {
    propertiesBlock.forEach(function (propertiesCarousel) {
      new Swiper(
        propertiesCarousel.querySelector('.three-properties__inner.swiper'),
        {
          draggable: true,
          slidesPerView: 1,
          spaceBetween: 20,
          a11y: {
            paginationBulletMessage: '',
            nextSlideMessage: 'Prochaine nouvelle',
            prevSlideMessage: 'Nouvelle précédente',
          },
          navigation: {
            nextEl: propertiesCarousel
              .closest('.three-properties')
              .querySelector('.three-properties-button-next'),
            prevEl: propertiesCarousel
              .closest('.three-properties')
              .querySelector('.three-properties-button-prev'),
          },
          pagination: {
            el: propertiesCarousel
              .closest('.three-properties')
              .querySelector('.three-properties-pagination'),
            type: 'fraction',
          },
          breakpoints: {
            451: {
              slidesPerView: 1.5,
            },
            769: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3,
            },
          },
        }
      );
    });
  }
});
